@tailwind base;
@tailwind components;
@tailwind utilities;

/* MODIFIED:  [ 2023-01-25 02:38:18 PM (OFFICE) ] */
.input-group,
.radio-group,
.lang-group,
.cb-group {
  @apply relative transition-all;
}

.input-btn-group {
  @apply relative;
}

.input-group ::placeholder,
.input-btn-group ::placeholder {
  @apply font-sans text-indigo-500;
}

/* Checkboxes and Radios */
.cb-group input[type="checkbox"] {
  @apply rounded;
}
.radio-group input[type="radio"],
.cb-group input[type="checkbox"] {
  @apply h-4 w-4 border-gray-300 bg-white focus:ring-indigo-500 dark:border-gray-400 dark:bg-gray-500 dark:ring-offset-gray-800 dark:focus:ring-indigo-400;
}
.radio-group input[type="radio"]:checked,
.cb-group input[type="checkbox"]:checked {
  @apply border-indigo-500 bg-indigo-500 ring-2 ring-indigo-600/25;
}

.cb-group > label,
.radio-group > label {
  @apply my-3 font-semibold;
}

.cb-group .cb-option,
.radio-group .cb-option {
  @apply mt-1 mr-4 inline-block w-full;
}

.cb-group .cb-option label,
.radio-group .cb-option label {
  @apply flex items-center text-sm;
}
.cb-group .cb-option span,
.radio-group .cb-option span {
  @apply ml-2 text-gray-500 dark:text-gray-200;
}

.cb-group input[type="checkbox"] {
  @apply h-5 w-5 rounded border-gray-300 text-indigo-600 transition focus:ring-indigo-500 dark:border-gray-900 dark:bg-gray-600 dark:ring-offset-gray-800;
}

.input-group input[type="text"],
.input-group input[type="email"],
.input-group input[type="number"],
.input-group input[type="password"] {
  @apply m-0 h-auto min-h-[50px] w-full transform rounded-md border border-gray-300 bg-white object-center p-3 font-medium text-gray-800 outline-none duration-150 ease-in-out focus:border-indigo-500 focus:shadow-none focus:outline-none focus:ring-4  focus:ring-indigo-600/25 dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-indigo-500/25 dark:focus:ring-offset-2 dark:focus:ring-offset-indigo-500;
}

/* used for language settings */
.lang-group input[type="text"] {
  @apply m-0 h-auto min-h-[50px] w-full transform truncate rounded-md border border-gray-300 bg-white object-center pt-6 font-normal text-gray-800 outline-none duration-150 ease-in-out placeholder:text-indigo-500 focus:border-indigo-500 focus:shadow-none focus:outline-none focus:ring-4 focus:ring-indigo-600/25 dark:border-transparent dark:bg-gray-800 dark:text-white dark:placeholder:text-indigo-500 dark:focus:border-indigo-300 dark:focus:ring-indigo-500/50 dark:focus:ring-offset-indigo-500/25;
}

/* Dropdown / Autoomplete (popup menu) */
.input-group.dropdown button {
  @apply inline-flex h-[50px] w-full transform items-center justify-between rounded-md border border-gray-300 bg-white p-3 pt-5 pl-3 pb-1 font-medium duration-150 ease-in-out focus:border-indigo-500 focus:border-indigo-500 focus:shadow-none focus:outline-none  focus:ring-4 focus:ring-indigo-600/25 dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-indigo-500/25 dark:focus:ring-offset-2 dark:focus:ring-offset-indigo-500;
}
.input-group.dropdown .menu,
.input-group.autocomplete .menu {
  @apply absolute left-0 z-10 mt-2 w-full origin-top-left rounded-md border border-gray-400 bg-white/90 py-2 shadow-md backdrop-blur-sm focus:outline-none dark:border-gray-500 dark:bg-gray-900/90 dark:shadow-none;
}
.input-group.dropdown .menu a,
.input-group.autocomplete .menu a {
  @apply block px-4 py-0.5 font-medium text-gray-500 hover:bg-indigo-100 hover:text-indigo-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white;
}

/* stepper for number inputs */
.input-group .stepper {
  @apply absolute top-0 right-0 bottom-0 flex w-8 flex-col items-center justify-center rounded-r border border-gray-300 bg-white dark:border-gray-700 dark:bg-gray-900/50;
}

.input-btn-group input[type="text"],
.input-btn-group input[type="email"],
.input-btn-group input[type="password"] {
  @apply m-0 h-auto w-full transform rounded-none rounded-l-md border border-gray-300 bg-transparent bg-white object-center p-3 text-sm  text-gray-800 shadow-none outline-none duration-150 ease-in-out;
}

.input-group.number input {
  @apply font-medium;
}

/* Textarea */
.input-group textarea {
  @apply m-0 w-full transform rounded-md border border-gray-300 bg-transparent bg-white object-center p-0 pl-3 pt-3 align-middle text-sm font-medium leading-6 text-gray-800 duration-150 ease-in-out focus:border-indigo-500 focus:shadow-none focus:outline-none  focus:ring-4 focus:ring-indigo-600/25 dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-indigo-500/25 dark:focus:ring-offset-2 dark:focus:ring-offset-indigo-500;
}

/* Select Menu */
.input-group select,
button.pseudo-select {
  @apply h-[50px] w-full transform rounded-md border-gray-300 bg-white p-3 pt-5 pl-3 pb-1 font-medium duration-150 ease-in-out focus:border-indigo-500 focus:shadow-none focus:outline-none  focus:ring-4 focus:ring-indigo-600/25 dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-indigo-500/25 dark:focus:ring-offset-2 dark:focus:ring-offset-indigo-500;
}

.input-group label,
.input-btn-group label {
  @apply absolute top-0 left-0 block pl-3 pt-3 text-xs font-semibold text-indigo-700 opacity-0 transition-opacity duration-150 ease-in dark:text-indigo-300;
}

.lang-group label {
  @apply absolute top-2 left-0 block pl-3  text-xs font-semibold text-indigo-700 dark:text-indigo-300;
}

.input-group textarea ~ label {
  @apply absolute top-3 left-2 rounded-full bg-white p-1 dark:bg-gray-800;
}

.input-btn-group ~ button {
  @apply relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700  transition duration-150 ease-in-out hover:bg-white hover:text-gray-500 focus:border-indigo-300 focus:outline-none active:bg-gray-100 active:text-gray-700;
}

.input-group select.form-select,
button.pseudo-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23737373' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M8 9l4-4 4 4m0 6l-4 4-4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.25em 1.25em;
  padding-right: 2.5rem;
  print-color-adjust: exact;
}

/* firefox fixes to remove red borders and focus */
.input-group input[type="text"]:required,
.input-group input[type="email"]:required,
.input-group input[type="password"]:required,
.input-group input[type="number"]:required {
  @apply shadow-none;
}

.input-group input[type="text"]:invalid,
.input-group input[type="email"]:invalid,
.input-group input[type="password"]:invalid,
.input-group input[type="number"]:invalid {
  @apply shadow-none outline-none;
}

/* // animated effect when typing starts ( and placeholder is gone ) */

/* //.input-group input:focus-within, */
.input-group input:not(:placeholder-shown),
.input-btn-group input:not(:placeholder-shown) {
  @apply p-4 pt-5 pl-3 pb-1;
}

.input-btn-group.pre-icon input:not(:placeholder-shown) {
  @apply p-4 pt-6 pl-10 pb-1;
}

.input-group input:not(:placeholder-shown) ~ .pre-icon,
.input-btn-group input:not(:placeholder-shown) ~ .pre-icon {
  @apply p-4 pt-6 pl-3 pb-1 text-sm;
}

.input-group textarea:not(:placeholder-shown) {
  @apply p-4 pt-8 pl-3 pb-1;
}

/* //.input-group input:focus-within ~ label, */
.input-group input:not(:placeholder-shown) ~ label,
.input-group textarea:not(:placeholder-shown) ~ label,
.input-group select ~ label,
.input-group button ~ label,
button.pseudo-select ~ label,
.input-btn-group input:not(:placeholder-shown) ~ label,
.input-btn-group textarea:not(:placeholder-shown) ~ label {
  @apply -translate-y-1 opacity-100 transition-opacity ease-in;
}

.input-group.error input[type="text"],
.input-group.error input[type="email"],
.input-group.error input[type="number"],
.input-btn-group.error input[type="text"],
.input-group.error input[type="password"],
.input-group.error textarea,
.input-group.error select {
  @apply border-2 border-red-300 text-red-500
  focus:border-red-300 focus:outline-none;
}

/* disable inputs during processing */
.processing .input-group,
.processing .input-btn-group {
  @apply pointer-events-none opacity-50;
}

.input-group.error input::placeholder,
.input-group.error textarea::placeholder {
  @apply text-red-400 opacity-100;
}

.tooltip svg {
  @apply text-gray-300 transition duration-100 ease-in-out hover:text-gray-400 dark:text-gray-600 dark:hover:text-gray-500;
}
.input-group .tooltip {
  @apply absolute top-4 right-0 mr-3 flex items-center;
}

.input-group.dropdown .tooltip {
  @apply mr-7;
}

.input-group.number .tooltip {
  @apply right-6;
}

.input-group.error .tooltip {
  @apply pointer-events-none absolute inset-y-1 right-0 mr-3 hidden items-center;
}

.input-group .error-icon,
.input-group .success-icon {
  @apply pointer-events-none absolute inset-y-1 right-0 mr-4 hidden items-center;
}

.input-group .clear-icon {
  @apply pointer-events-none absolute inset-y-0 right-0 mr-3 flex hidden items-center text-gray-300 transition dark:text-gray-600 dark:hover:text-gray-500;
}

.input-group.error .error-icon {
  @apply pointer-events-none absolute inset-y-0 right-0 mr-4 flex items-center;
}

#summary-col .input-group.error .error-icon {
  @apply mr-8;
}

.input-group.success .success-icon {
  @apply pointer-events-none absolute inset-y-0 right-0 mr-8 flex items-center;
}

.input-group.success .clear-icon {
  @apply pointer-events-auto absolute inset-y-0 right-0 mr-3 flex cursor-pointer items-center hover:text-gray-500;
}

.input-group input:not(:placeholder-shown) ~ .clear-icon {
  @apply pointer-events-auto absolute inset-y-0 right-0 mr-3  flex cursor-pointer items-center hover:text-gray-500;
}

/*
.input-group.success input[type='text'],
.input-group.success input[type='email'] {
  @apply border-2 border-indigo-500 ring-2 ring-indigo-200
}
*/

.radio-group.error .form-radio,
.cb-group.error .form-checkbox,
.accept-terms.error .slide-toggle {
  @apply border-red-400;
}

.radio-group.error .error-icon,
.cb-group.error .error-icon,
.accept-terms.error .error-icon {
  @apply pointer-events-none relative mr-2 inline flex items-center;
}

.radio-group .error-msg,
.cb-group .error-msg,
.accept-terms .error-msg,
.accept-terms .error-icon {
  @apply hidden;
}

.radio-group.error .error-msg,
.cb-group.error .error-msg,
.accept-terms.error .error-msg {
  @apply block;
}

.input-group .error-msg {
  @apply mt-1 hidden truncate text-xs text-red-600;
}

.input-group.error .error-msg {
  @apply mt-1 block truncate text-xs text-red-600;
}

.input-group .success-msg {
  @apply mt-1 hidden truncate text-xs text-indigo-600 transition;
}

.input-group.success .success-msg {
  @apply mt-1 block truncate text-xs text-indigo-600 transition;
}

/* !TOGGLE INPUT */
.toggle-group {
  @apply m-0 flex h-auto min-h-[50px] w-full transform items-center justify-between rounded-md border border-gray-300 bg-white object-center p-3 font-medium text-gray-800 outline-none duration-150 ease-in-out dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-indigo-500/25 dark:focus:ring-offset-2 dark:focus:ring-offset-indigo-500;
}
.toggle-group label {
  @apply flex items-center space-x-1;
}
.toggle-group button {
  @apply relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600/50 focus:ring-offset-2 dark:ring-offset-indigo-500;
}
.toggle-group button .handle {
  @apply pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out dark:bg-gray-800;
}
.toggle-group button .handle span.no {
  @apply absolute inset-0 flex h-full w-full items-center justify-center text-gray-400 transition-opacity;
}
.toggle-group button .handle span.yes {
  @apply absolute inset-0 flex h-full w-full items-center justify-center text-indigo-600 transition-opacity dark:text-gray-200;
}
